import { toast } from 'react-toastify';
import { useEffectOnce } from 'react-use';

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';

import { setAppConfig } from '@features/appConfig/appConfigSlice';
import i18n from '@features/i18n';
import { useAppDispatch } from '@hooks';
import { isValidTimezone } from '@utils/isValidTimezone';
import { parseSearchParams } from '@utils/parseUrlParams';

const getErrorMessage = (invalidTimezone?: string) =>
  invalidTimezone
    ? i18n.t('common.toast.error.tz.invalid.specified', { invalidTimezone })
    : i18n.t('common.toast.error.tz.invalid');

const getNormalizedTimezone = (timeZone: string) => {
  const isGMT = dayjs.tz(0, timeZone).offsetName() === 'GMT';

  return isGMT ? 'UTC' : timeZone;
};

export const useTimezone = () => {
  const dispatch = useAppDispatch();

  const queryParamTimezone = parseSearchParams(
    window.location.search,
    'timezone'
  );

  useEffectOnce(() => {
    if (!queryParamTimezone) {
      return;
    }

    if (isValidTimezone(queryParamTimezone)) {
      dispatch(
        setAppConfig({
          timezone: getNormalizedTimezone(queryParamTimezone),
        })
      );
    } else {
      toast.error(getErrorMessage());
      Sentry.captureException(getErrorMessage(queryParamTimezone));
      console.error(getErrorMessage(queryParamTimezone));
    }
  });
};
