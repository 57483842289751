import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { activeItem } from '@features/menu/menuSlice';
import { useAppDispatch } from '@hooks';

const menuPaths = [
  'automatic-campaigns',
  // Description: Remove after Orbit impl in BS-4097
  'campaign-management',
  'scheduled-campaigns',
  'bonus-management',
  'give-bonus',
  'player-codes',
  'activate-code',
  'player-offers',
  'player-bonuses',
  'activity-report',
  'app-settings',
  'monitor-report',
  'code-campaigns',
  'iframe-mode',
];

export const useActiveItemUi = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const item = menuPaths.find((el) => location.pathname.includes(el));
    dispatch(activeItem({ openItem: [item ?? 'bonus-management'] }));
  }, [dispatch, location]);
};
