import { useMemo } from 'react';

import { SYSTEM_ROLES } from '@constants';
import { useAppSelector } from '@hooks';

interface IUserRole {
  isUserSuperAdmin?: boolean;
  hasAddPermission?: boolean;
  hasEditPermission?: boolean;
  hasReadPermission?: boolean;
  hasCancelPermission?: boolean;
  hasExportPermission?: boolean;
  hasCreatePermission?: boolean;
}

export const usePermissions = (
  module: SYSTEM_ROLES.PermissionModules
): IUserRole => {
  const userType = useAppSelector((state) => state.auth.userType);
  const permissions = useAppSelector((state) => state.auth.permissions);

  const isUserSuperAdmin = useMemo(
    () => userType === SYSTEM_ROLES.ROLE.SUPER_ADMIN,
    [userType]
  );

  const {
    hasAddPermission,
    hasReadPermission,
    hasEditPermission,
    hasExportPermission,
    hasCancelPermission,
    hasCreatePermission,
  } = useMemo(() => {
    let hasReadPermission = false;
    let hasEditPermission = false;
    let hasExportPermission = false;
    let hasCancelPermission = false;
    let hasCreatePermission = false;
    let hasAddPermission = false;

    permissions.forEach((p) => {
      if (p?.split(':')?.[1] === module) {
        if (p.includes('edit:')) {
          hasEditPermission = true;
        }
        if (p.includes('export:')) {
          hasExportPermission = true;
        }
        if (p.includes('read:')) {
          hasReadPermission = true;
        }
        if (p.includes('cancel:')) {
          hasCancelPermission = true;
        }
        if (p.includes('create:')) {
          hasCreatePermission = true;
        }
        if (p.includes('add:')) {
          hasAddPermission = true;
        }
      }
    });

    return {
      hasAddPermission,
      hasReadPermission,
      hasEditPermission,
      hasExportPermission,
      hasCancelPermission,
      hasCreatePermission,
    };
  }, [module, permissions]);

  return {
    isUserSuperAdmin,
    hasAddPermission,
    hasReadPermission,
    hasEditPermission,
    hasExportPermission,
    hasCancelPermission,
    hasCreatePermission,
  };
};
