import isArray from 'lodash/isArray';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useGetEnumsQuery,
  useGetLocalesQuery,
  useGetTimeZonesQuery,
} from '@api/systemValuesApiSlice';
import { type SystemOptionValue } from '@api/types';
import { type Option } from '@shared/form/types';

type Value = number | string;

export interface IGetOptions {
  key: SystemOptionValue;
  withDisabled?: boolean;
  allowedValues?: Value[];
  disabledValues?: Value[];
}

export interface IGetLabel {
  key: SystemOptionValue;
  value: number | string;
}

interface IUseCatalog {
  isLoading: boolean;
  localeOptions: Option[];
  countryOptions: Option[];
  currencyOptions: Option[];
  timeZoneOptions: Option[];
  getLabel: (data: IGetLabel) => string;
  getOptions: (data: IGetOptions) => Option[];
}

const POLLING_INTERVAL: number = 300000;

export const useCatalog = (): IUseCatalog => {
  const { data: catalogEnums, isLoading: isLoadingEnums } = useGetEnumsQuery(
    '',
    { pollingInterval: POLLING_INTERVAL }
  );
  const { data: timeZoneOptions = [], isLoading: isLoadingTimeZones } =
    useGetTimeZonesQuery('', { pollingInterval: POLLING_INTERVAL });
  const { data: countryOptions = [], isLoading: isLoadingCountries } =
    useGetCountriesQuery('', { pollingInterval: POLLING_INTERVAL });
  const { data: currencyOptions = [], isLoading: isLoadingCurrencies } =
    useGetCurrenciesQuery('', { pollingInterval: POLLING_INTERVAL });
  const { data: localeOptions = [], isLoading: isLoadingLocales } =
    useGetLocalesQuery('', { pollingInterval: POLLING_INTERVAL });

  const { t } = useTranslation();

  const translateOptions = useCallback(
    <T extends Option | Option[]>(data: T): T => {
      if (isArray(data)) {
        return data.map(({ label, translationKey, ...option }) => ({
          ...option,
          label: translationKey ? t(translationKey) : label,
        })) as T;
      }

      return {
        ...data,
        label: data.translationKey ? t(data.translationKey) : data.label,
      };
    },
    [t]
  );

  const getOptions = useCallback(
    ({
      key,
      allowedValues,
      disabledValues,
      withDisabled = true,
    }: IGetOptions): Option[] => {
      if (!catalogEnums) {
        return [];
      }

      const options = translateOptions(catalogEnums[key] ?? []);

      if (allowedValues?.length) {
        return options
          .map((option) => ({
            ...option,
            disabled: withDisabled && !allowedValues.includes(option.value),
          }))
          .filter(
            (option) => withDisabled || allowedValues.includes(option.value)
          );
      }

      if (disabledValues?.length) {
        return options
          .map((option) => ({
            ...option,
            disabled: withDisabled && disabledValues.includes(option.value),
          }))
          .filter(
            (option) => withDisabled || disabledValues.includes(option.value)
          );
      }

      return options;
    },
    [catalogEnums, translateOptions]
  );

  const getLabel = useCallback(
    ({ key, value }: IGetLabel): string => {
      if (!catalogEnums) {
        return '';
      }

      const option = catalogEnums[key].find((option) => option.value === value);

      return option ? translateOptions(option).label : '';
    },
    [catalogEnums, translateOptions]
  );

  const isLoading = useMemo(
    () =>
      isLoadingEnums ||
      isLoadingTimeZones ||
      isLoadingCountries ||
      isLoadingCurrencies ||
      isLoadingLocales,
    [
      isLoadingEnums,
      isLoadingTimeZones,
      isLoadingCountries,
      isLoadingCurrencies,
      isLoadingLocales,
    ]
  );

  return {
    getLabel,
    isLoading,
    getOptions,
    localeOptions,
    countryOptions,
    timeZoneOptions,
    currencyOptions,
  };
};
