import {
  type ColumnSort,
  type SortingState,
  type Updater,
} from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';

import { type Action as ReduxAction } from '@reduxjs/toolkit';

import { type ISearchParams } from '@features/filters/types';
import { convertSortingPayloadToTableValue } from '@features/filters/utils/convertSortingPayloadToTableValue';
import { convertSortingToSearchPayload } from '@features/filters/utils/convertSortingToSearchPayload';
import { useAppDispatch } from '@hooks';

type Action = (data: ISearchParams) => ReduxAction;

export const useSetSearchParams = ({
  action,
  searchParams,
}: {
  action: Action;
  searchParams: ISearchParams;
}) => {
  const dispatch = useAppDispatch();

  const [sortingTable, setSortingTable] = useState<SortingState>([]);

  useEffect(() => {
    const defaultSortingState = convertSortingPayloadToTableValue(
      searchParams.sorts
    );
    setSortingTable(defaultSortingState);
  }, [searchParams.sorts]);

  const setPageIndex = useCallback(
    (page: number): void => {
      dispatch(action({ ...searchParams, page }));
    },
    [action, dispatch, searchParams]
  );

  const setPageSize = useCallback(
    (size: number): void => {
      dispatch(action({ ...searchParams, size, page: 1 }));
    },
    [action, dispatch, searchParams]
  );

  const setSorting = useCallback(
    (sorting: (old: SortingState) => Updater<SortingState>): void => {
      const internalSortingTableData = sorting(sortingTable);
      const sortingPayload = convertSortingToSearchPayload(
        internalSortingTableData as ColumnSort[]
      );

      setSortingTable(internalSortingTableData);
      dispatch(action({ ...searchParams, sorts: sortingPayload }));
    },
    [action, dispatch, searchParams, sortingTable]
  );

  return {
    setSorting,
    setPageSize,
    searchParams,
    setPageIndex,
    sorting: sortingTable,
  };
};
